const navToggle = document.querySelector(".nav-toggle");
const mainNav = document.querySelector(".main-nav");

/// CLICK EVENTS
document.addEventListener("click", function (e) {
  // Close open nav & mega menus
  if (
    mainNav && e.target &&
    !e.target.closest(
      ".main-nav .logo-wrap, .main-nav .menu,  .nav-toggle"
    )
  ) {
    mainNav.classList.remove("open");
    openMenus = document.querySelector(".mega-menu.open");
    if (openMenus) {
      openMenus.classList.remove("open");
    }
  }
  // Mega Menu Back buttons
  if (e.target && e.target.closest(".mega-back")) {
    openMenus = document.querySelector(".mega-menu.open");
    if (openMenus) {
      openMenus.classList.remove("open");
    }
  }

  // Toggle main nav
  if (e.target && e.target.closest(".nav-toggle")) {
    mainNav.classList.toggle("open");
  }

  // Mega Menu mobile buttons
  if (e.target && e.target.closest(".menu-item-has-children > a + button")) {
    e.preventDefault();
    const parentLink = e.target.closest(".menu-item-has-children > a + button");
    const megaMenu = parentLink.nextElementSibling;    
    megaMenu.classList.toggle("open");
  }
  if (e.target && e.target.closest(".menu-3 > .menu-item-has-children > a")) {
    e.preventDefault();
    
    const parentLink = e.target.closest(".menu-item-has-children > a");
    
    const megaMenu = parentLink.nextElementSibling;
    if (megaMenu.querySelector('#s')) {    
      setTimeout(function () {
        megaMenu.querySelector('#s').focus();        
      }, 100);
    }
    megaMenu.classList.toggle("open");
  }
});
