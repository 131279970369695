// Variable to store modal-trigger element for returning focus on close.
let returnEl;

document.addEventListener("click", function (e) {
  // Open modal
  if (e.target && e.target.closest("[data-modal-toggle]")) {
    e.preventDefault();
    const trigger = e.target.closest("[data-modal-toggle]");
    const target = document.querySelector(
      "[data-id=" + trigger.dataset.modalToggle + "]"
    );
    openModal(target);
    trapFocus(target, trigger);
  }

  // Close modal
  if (e.target && e.target.closest("[data-modal-close]")) {
    e.preventDefault();
    const button = e.target.closest("[data-modal-close]");
    closeModal(button.closest(".dc-modal"));
  }

  // Background-click close
  if (e.target && e.target.matches(".dc-modal") && e.target.dataset.bgClose != "false") {
    closeModal(e.target);
  }
});

const openModal = (modal) => {
  modal.classList.add("open");
};
const closeModal = (modal) => {
  modal.classList.remove("open");
  returnEl.focus();
};

// Trap focus inside modal when active.
const trapFocus = (el, trigger) => {
  returnEl = trigger;
  var focusableEls = el.querySelectorAll(
    'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
  );
  var firstFocusableEl = focusableEls[0];
  if (el.dataset.firstFocusSelector) {
    var firstFocus = el.querySelector(el.dataset.firstFocusSelector);    
  }
  var lastFocusableEl = focusableEls[focusableEls.length - 1];
  var KEYCODE_TAB = 9;
  var modalStyle = getComputedStyle(el);
  var transition = parseFloat(modalStyle.transitionDuration) * 200;

  // Timeout required for Chrome bug & transition
  setTimeout(function () {
    if (firstFocus) {
      firstFocus.focus();
      console.log("special");
    } else {
      firstFocusableEl.focus();
      console.log("normal");
    }
    
  }, transition);

  el.addEventListener("keydown", function (e) {
    var isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;
    if (!isTabPressed) {
      return;
    }
    if (e.shiftKey) {
      /* shift + tab */ if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus();
        e.preventDefault();
      }
    } /* tab */ else {
      if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus();
        e.preventDefault();
      }
    }
  });
};
