

var Flickity = require("flickity");
var Isotope = require("isotope-layout");

document.addEventListener("DOMContentLoaded", (e) => {

  window.dispatchEvent(new Event('resize'));

  // Init home slider flickity
  var homeSlider = document.querySelector(
    '.header-slider .carousel'
  );
  if (homeSlider){
    var flkty = new Flickity(homeSlider, {
      // options
      cellAlign: "left",
      contain: true,
      prevNextButtons: false,
      // imagesLoaded: true
      setGallerySize: false
    });
   
  }


//  Init courseSearch isotope

 var searchVal;
 var courseSearch = document.querySelector(
  "[data-id='course-search'] .content"
);
if (courseSearch) {
  var iso = new Isotope( "[data-id='course-search'] .content", {
    // options
    itemSelector: 'a',
    layoutMode: 'vertical',
    filter: function() {
      var keywords = jQuery(this).attr('data-keywords').toLowerCase();
      var search = true;
      if (searchVal && keywords) {
        searchVal.forEach(function(val){
          if (!keywords.includes(val)) {
            search = false;
          }
        })
      }  
      return search;
    }
  });
}
 

// use value of search field to filter
var quicksearch = document.querySelector("[data-id='course-search'] .big-search");
if (quicksearch) {
  quicksearch.addEventListener(
    "keyup",
    debounce(function() {
      searchVal = quicksearch.value.toLowerCase().split(" ");
      iso.arrange();
    }, 200)
  );
}
});


function debounce(fn, threshold) {
  var timeout;
  threshold = threshold || 100;
  return function debounced() {
    clearTimeout(timeout);
    var args = arguments;
    var _this = this;

    function delayed() {
      fn.apply(_this, args);
    }
    timeout = setTimeout(delayed, threshold);
  };
}