window.onscroll = function(ev) {

    const header = document.querySelector('[is=flynt-hero-header]');
    const headerPos = header ? header.getBoundingClientRect() : null;

    // Window is within 10px of the top
    if (window.scrollY <=  10) {
        document.body.classList.add("at-top");
    } else {
        document.body.classList.remove("at-top");
    }

    // Window has scrolled passed the header
    if (headerPos){
        if (headerPos.bottom < 0) {
            document.body.classList.add("passed-header");
        } else {
            document.body.classList.remove("passed-header");
        }
    }
    

    // Window is within 10px of the bottom
    if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - 10) {
        document.body.classList.add("at-bottom");
    } else {
        document.body.classList.remove("at-bottom");
    }

};