
var Flickity = require("flickity");
var Isotope = require("isotope-layout");

document.addEventListener("DOMContentLoaded", (e) => {

  // Init home slider flickity
  var testimonialsSlider = document.querySelector(
    '[is="flynt-block-testimonials"] .carousel'
  );
  if (testimonialsSlider){
    var flkty = new Flickity(testimonialsSlider, {
      // options
      // cellAlign: "center",
      contain: true,
      prevNextButtons: false,
      imagesLoaded: true,
      // setGallerySize: false
    });
  }


});