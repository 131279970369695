document.addEventListener("click", function(e) {
  if (e.target && e.target.matches(".tab-control")) {
    var toggle = e.target;
    var toggleWrap = toggle.closest(".tab-menu");
    var toggleSiblings = toggleWrap.querySelectorAll(".tab-control");
    var target = document.getElementById(toggle.dataset.tabfor);
    var targetWrap = target.closest(".tabs-wrap");
    var targetSiblings = targetWrap.querySelectorAll(".tab-content");

    toggleSiblings.forEach(function(el) {
      el.classList.remove("active");
      if (el === toggle) {
        el.classList.add("active");
      }
    });
    targetSiblings.forEach(function(el) {
      el.classList.remove("active");
      if (el === target) {
        el.classList.add("active");
      }
    });
  }
});
