var Flickity = require("flickity");
var Isotope = require("isotope-layout");

document.addEventListener("DOMContentLoaded", (e) => {


  // Init home slider flickity
  var logoSlider = document.querySelector(
    '.logo-carousel'
  );
  if (logoSlider){
    var flkty = new Flickity(logoSlider, {
      // options
      cellAlign: "left",
      contain: true,
      autoPlay: 3000,
      prevNextButtons: false,
      imagesLoaded: true,
      wrapAround: true,
      selectedAttraction: 0.01,
      friction: 0.25,
      pageDots: false,
    });
   
  }
});